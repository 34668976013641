<template>
  <div class="dry-service">
    <moduleTitle title="烘干服务"></moduleTitle>
    <div class="dry-service-echart">
      <Echart :option="option"></Echart>
    </div>
  </div>
</template>

<script>
import moduleTitle from "./components/moduleTitle.vue";
import Echart from "../../components/echarts/index.vue";
import option from "./config/service.js";
import { dryingchart } from "@/api/njApi/honggan.js";

export default {
  components: {
    moduleTitle,
    Echart,
  },
  data() {
    return {
      option,
    };
  },
  created() {
    // this.getData();
  },
  mounted() {
    let address = localStorage.getItem("address");
    dryingchart({
      name: address + "烘干",
    })
      .then((res) => {
       

        this.option.series[0].data = res.data.dryServer.map((element) => {
          return element.dryingAccout;
        });
        this.option.series[1].data = res.data.dryServer.map((element) => {
          return element.dryingAccountMyself;
        });
        this.option.series[2].data = res.data.dryServer.map((element) => {
          return element.outServerAccount;
        });
        res.data.dryServer.forEach((ele) => {
          this.option.xAxis.data.push({
            value: ele.dryingYear,
            textStyle: {
              color: "#ffffff",
              // fontSize:12,
            },
          });
        });
    
  
       
      })
      .catch((err) => {
        console.log(err);
      });
  },
  method: {
    getData() {},
  },
};
</script>

<style  lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

.dry-service {
  position: relative;
  width: companyW(482vw);
  height: companyH(322vh);
  background: rgba(0, 20, 36, 0.4);
  border: companyW(1vw) solid;
  border-image: linear-gradient(0deg, #e69635, #21261a) 10 10;

  // opacity: 0.74;
  .dry-service-echart {
    margin: 0 auto;
    margin-top: companyH(40vh);
    width: companyW(400vw);
    height: companyH(260vh);
  }
}
</style>
