<template>
  <div class="middle-navList">
    <div class="middle-navList-item" v-for="(item, index) in navList" :key="index">
      <span class="navList-item-title">{{ item.name }}</span>
      <div class="navList-item-desc" :style="{ backgroundImage: `url(${item.bgcTitle})` }">
        {{ item.number }}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    Option: {
      type: Array,
      require: true,
      default: () => { }
    }
  },
  watch: {
    Option: {
      deep: true,
      handler(newValue) {

        this.navList = newValue;
      }
    }
  },
  data() {
    return {
      navList: [
        {
          name: '批次烘干能力',
          number: '1000',
          bgcTitle: require('../../../assets/drying-page/nav_bgc.png')
        },
        {
          name: '批次烘干能力',
          number: '1000',
          bgcTitle: require('../../../assets/drying-page/nav_bgc.png')
        },
        {
          name: '批次烘干能力',
          number: '1000',
          bgcTitle: require('../../../assets/drying-page/nav_bgc.png')
        }
      ]
    };
  }
};
</script>

<style  lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

@font-face {
  font-family: Source Han Sans CN;
  src: url(../../../assets/font/np4SotSdlbui.woff);
}

.middle-navList {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  .middle-navList-item {
    width: companyW(208vw);
    height: 100%;
    position: relative;

    .navList-item-title {
      position: absolute;
      top: companyH(-7vh);
      left: 50%;
      transform: translate(-50%);
      height: companyH(16vh);
      font-size: companyH(16vh);
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
      line-height: companyH(16vh);
    }

    .navList-item-desc {
      width: 100%;
      text-align: center;
      height: companyH(65vh);
      // background-image: url(../../../assets//drying-page/nav_bgc.png);
      background-repeat: no-repeat;
      background-size: contain;
      font-size: companyH(26vh);
      font-family: Source Han Sans SC;
      font-weight: 500;
      color: #FFFFFF;
      line-height: companyH(65vh);
    }
  }
}
</style>
