  export let value = 10;

export const dbsnOption = {
  title: [
    {
      text: `{a|${ value }}{b|%}`,
      x: 'center',
      y: '40%',
      textStyle: {
        rich: {
          a: {
            fontSize: 30,
            color: '#fff',
          },
          b: {
            fontSize: 16,
            color: '#fff',
            padding: [ 0, 0, 0, 0 ],
          },
        },
      },
    },
    {
      text: '对比上年情况',
      bottom: 0,
      x: 'center',
      textStyle: {
        color: '#fff',
        fontSize:16
      },
    },
  ],
  series: [
    {
      type: 'gauge',
      radius: '58%',
      clockwise: false,
      startAngle: '90',
      endAngle: '-269.9999',
      splitNumber: 25,
      detail: {
        offsetCenter: [ 0, -20 ],
        formatter: ' ',
      },
      pointer: {
        show: false,
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: [
            [ 0, '#2CFAFC' ],
            [ 78 / 100, 'rgba(32,187,252,0.15)' ],
            [ 1, '#FFA235' ],
          ],
          width: 8,
        },
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: true,
        length: 10,
        lineStyle: {
          color: '#051F54',
          width: 2,
        },
      },
      axisLabel: {
        show: false,
      },
    },


  ],
};
