<template>
  <div class="middle">
    <div class="middle-nav">
      <navList :Option="navList" />
    </div>
    <storageAndDry />
    <div class="middle-tray">
    </div>
  </div>
</template>

<script>
import { watch } from 'fs';
import navList from '../components/navList.vue';
import storageAndDry from './storageAndDry.vue';
export default {
  components: {
    navList,
    storageAndDry,
  },
  data() {
    return {
      navList: [
        {
          name: '批次烘干能力',
          number: '1000',
          bgcTitle: require('../../../assets/drying-page/nav_bgc.png')
        },
        {
          name: '当年已烘数量',
          number: '1000',
          bgcTitle: require('../../../assets/drying-page/nav_bgc.png')
        },
        {
          name: '待烘数量',
          number: '1000',
          bgcTitle: require('../../../assets/drying-page/nav_bgc.png')
        }
      ]
    };
  },
  created() {

  },
  props: ['dryingstoreData'],
  watch: {
    dryingstoreData: {
      deep: true,
      handler(newValue) {
        console.log(newValue);
        this.navList[0].number = newValue.dryingPower.split('.')[0];
        this.navList[1].number = newValue.dryingYearCount.split('.')[0];
        this.navList[2].number = newValue.dryingCountWait.split('.')[0];
      }
    }
  }
};
</script>

<style  lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

.middle {
  position: relative;
  width: companyW(916vw);
  height: companyH(978vh);
  overflow: hidden;

  .middle-nav {
    width: companyW(716vw);
    height: companyH(65vh);
    margin: 0 auto;
    margin-top: companyH(44vh);
  }

  .middle-tray {
    position: absolute;
    margin-left: companyW(-70vw);
    // margin-bottom:  companyH(270vh);
    bottom: companyH(160vh);
    width: companyW(1092vw);
    height: companyH(230vh);
    z-index: 1;
    background-image: url(../../../assets/drying-page/tray.png);
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0.6;
  }
}
</style>
