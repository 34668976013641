<template>
  <div class="dry-lastYear">
    <moduleTitle title="对比上年情况"></moduleTitle>
    <div class="dry-lastYear-content">
      <div class="lastYear-content-echart">
        <Echart :option="dbsnOption"></Echart>
      </div>
    </div>

    <div class="lastYear-content-top">
      <div class="content-top-item">
        <div>
          <div class="little-box"></div>
          <span class="top-item-name">本年烘干量</span>
        </div>
        <div class="top-item-number">
          {{ dryingchartData.lastYearCondition[0].process_count }}吨
        </div>
      </div>

      <div class="content-top-item">
        <div>
          <div class="little-box-red"></div>
          <span class="top-item-name">上年烘干量</span>
        </div>
        <div class="top-item-number-red">
          {{ dryingchartData.lastYearCondition[1].process_count }}吨
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moduleTitle from './components/moduleTitle.vue';
import Echart from '../../components/echarts/index.vue';
import Option from './config/lastyear.js';
import * as ybmock from '@/views/drying/config/ybpChart.js';
// import {value } from '@/views/processAll/process/config/ybpChart.js';
import { dryingchart } from '@/api/njApi/honggan.js';

export default {
  components: {
    moduleTitle,
    Echart,
  },
  data() {
    return {
      Option,
      ...ybmock,
      dryingchartData: ''

    };
  },
  // props: [ 'dryingchartData' ],
  created() {
    let address = localStorage.getItem('address')
    // 获取烘干服务及对比上年情况
    dryingchart({
      name: address+'烘干'
    }).then(res => {
      this.dryingchartData = res.data;
      this.value = this.dryingchartData.lastYearCondition[0].process_count / this.dryingchartData.lastYearCondition[1].process_count * 100
      this.dbsnOption.title[0].text = `{a|${((this.dryingchartData.lastYearCondition[0].process_count / this.dryingchartData.lastYearCondition[1].process_count) * 100).toFixed(2)}}{b|%} `
      this.dbsnOption.series[0].axisLine = {
        show: true,
        lineStyle: {
          color: [
            [0, '#2CFAFC'],
            [this.value / 100, 'rgba(32,187,252,0.15)'],
            [1, '#FFA235'],
          ],
          width: 8,
        },
      }
    }).catch((err => {
      console.log(err);
    }));

  },
  mounted() {

  },
  watch: {


  },

};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

.dry-lastYear {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: companyW(482vw);
  height: companyH(322vh);
  background: rgba(0, 20, 36, 0.4);
  border: companyW(1vw) solid;
  border-image: linear-gradient(0deg, #e69635, #21261a) 10 10;

  // opacity: 0.74;
  .dry-lastYear-content {
    margin: 0 auto;
    margin-top: companyH(50vh);
    width: companyW(300vw);
    height: companyH(260vh);
    margin-left: companyH(30vh) ;

    .lastYear-content-echart {
      width: companyH(207vh);
      height: companyH(207vh);
      background-image: url(../../assets/drying-page/orange-echars.png);
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  .lastYear-content-top {
    margin-top: companyH(93vh);
    margin-right: companyW(50vw);
    width: companyW(217vw);
    height: companyH(161vh);

    // margin-top: ;
    .content-top-item {
      width: companyW(150vw);
      // height: companyH(60vh);

      .top-item-name {
        margin-left: companyW(5vw);
        display: inline-block;
        margin-bottom: companyH(7vh);
        height: companyH(13vh);
        font-size: companyH(12vh);
        font-family: Source Han Sans CN;
        font-weight: 700;
        color: #eff0f1;
        line-height: companyH(17vh);
      }

      .little-box {
        display: inline-block;
        width: companyW(6vw);
        height: companyH(8vh);
        background-color: #e69635;
      }

      .little-box-red {
        display: inline-block;
        width: companyW(6vw);
        height: companyH(8vh);
        background-color: #e65635;
      }

      .top-item-number {
        text-align: center;
        margin-left: companyW(7vw);
        width: 100%;
        height: companyH(47vh);
        background-image: url(../../assets/drying-page/power.png);
        background-size: cover;
        font-size: companyH(16vh);
        font-family: DIN;
        font-weight: 700;
        color: #ffffff;
        line-height: companyH(47vh);
      }

      .top-item-number-red {
        margin-left: companyW(7vw);
        text-align: center;
        width: 100%;
        height: companyH(47vh);
        background-image: url(../../assets/drying-page/lastYear-red.png);
        background-size: cover;
        font-size: companyH(16vh);
        font-family: DIN;
        font-weight: 700;
        color: #ffffff;
        line-height: companyH(47vh);
      }
    }
  }
}</style>
