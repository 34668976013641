<template>
  <div class="dry-power">
    <moduleTitle title="烘干能力"></moduleTitle>
    <div class="dry-power-content">
      <div class="power-content-top">
        <div class="content-top-item">
          <span class="top-item-name">烘干中心面积(㎡)</span>
          <div class="top-item-number">
            {{ powerData.dryingCenterArea }}
          </div>
        </div>
        <div class="content-top-item">
          <span class="top-item-name">堆场面积(㎡)</span>
          <div class="top-item-number">
            {{ powerData.heapArea }}
          </div>
        </div>
        <div class="content-top-item">
          <span class="top-item-name">烘干塔数量</span>
          <div class="top-item-number">
            {{ powerData.dryingTowerNum }}
          </div>
        </div>
      </div>
      <div class="power-content-bottom power-content-top">
        <div class="content-top-item">
          <span class="top-item-name">烘干能力(吨/批次)</span>
          <div class="top-item-number">
            {{ powerData.dryingPower }}
          </div>
        </div>
        <div class="content-top-item">
          <span class="top-item-name">湿谷仓面积(㎡)</span>
          <div class="top-item-number">
            {{ powerData.weightWarehouseArea }}
          </div>
        </div>
        <div class="content-top-item">
          <span class="top-item-name">干谷仓面积(㎡)</span>
          <div class="top-item-number">
            {{ powerData.dryWarehouseArea }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moduleTitle from './components/moduleTitle.vue';
export default {
  components: {
    moduleTitle
  },
  props: ['powerData']
};
</script>

<style  lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

.dry-power {
  position: relative;
  width: companyW(482vw);
  height: companyH(292vh);
  background: rgba(0, 20, 36, 0.4);
  border: companyW(1vw) solid;
  border-image: linear-gradient(0deg, #E69635, #21261A) 10 10;

  // opacity: 0.74;
  .dry-power-content {
    margin: 0 auto;
    margin-top: companyH(90vh);
    width: companyW(422vw);
    height: companyH(130vh);

    // background-color: red;
    .power-content-top {
      width: 100%;
      height: companyH(51vh);
      display: flex;
      justify-content: space-evenly;
      // justify-content: space-between;
      align-items: center;

      // background-color: #E69635;
      .content-top-item {
        width: companyW(140vw);
        height: companyH(57vh);
        

        .top-item-name {
          margin-left: companyW(5vw);
          display: inline-block;
          margin-bottom: companyH(7vh);
          height: companyH(13vh);
          font-size: companyW(16vw);
          font-family: Source Han Sans CN;
          font-weight: 700;
          color: #EFF0F1;
          line-height: companyH(17vh);
        }

        .top-item-number {
          text-align: center;
          width: 100%;
          height: companyH(47vh);
          background-image: url(../../assets/drying-page/power.png);
          background-repeat: no-repeat;
          background-size: cover;

          font-size: companyH(16vh);
          font-family: DIN;
          font-weight: 700;
          color: #FFFFFF;
          line-height: companyH(47vh);
        }
      }
    }

    .power-content-bottom {
      margin-top: companyH(39vh);
    }
  }
}</style>
