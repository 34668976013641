<template>
  <div class="storage">
    <div class="bgcBox">
      <div class="storage-item-echart">
        <Echart :option="DryOption" />
      </div>

    </div>
    <div class="honggan">
      正在烘干:{{ dun }}吨
    </div>
  </div>
</template>

<script>
import Echart from "../../../components/echarts/index.vue";
import option from "../config/storage.js";
import DryOption from "../config/dry.js";
import { dryingPowerData } from "@/api/njApi/honggan.js";

export default {
  components: {
    Echart,
  },
  data() {
    return {
      option,
      DryOption,
      dun: ''
    };
  },
  created() {
    // 获取顶部栏和两个球状数据
    dryingPowerData({})
      .then((res) => {
        console.log(res);
        this.DryOption.title[0].text = `当前空闲能力：${res.data.countNo}吨`;
        this.dun = res.data.countAlready

      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>

<style  lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

.storage {
  width: companyW(650vw);
  height: companyH(555vh);
  width: 100%;
  margin: 0 auto;
  margin-top: companyH(119vh);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bgcBox {
  width: companyW(460vw);
  height: companyH(460vh);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../../assets/drying-page/shuizhu.png);
  display: flex;
  justify-content: center;
  align-items: center;
  // border-radius: 50%;
}

.storage-item-echart {
  width: companyW(380vw);
  height: companyH(380vh);
}

.honggan {
  font-size: 36px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #FFFFFF;
  margin-top: companyH(40vh);
}
</style>
