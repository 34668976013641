var value = 0.752;
var value1 = 75.2;
var value2 = 75.2;
 var data = [ value, value, value ];

var option = {
  backgroundColor: 'transparent',
  title: [
    {
      text: `当前空闲能力：${ value1 }吨`,
      left: '50%',
      top: '50%',
      textAlign: 'center',
      textStyle: {
        fontSize: '22',
        fontWeight: '400',
        color: '#fff',

        textAlign: 'center',
      },
    },
    {
      // text: 'M',
      left: '61.5%',
      top: '58.5%',
      textAlign: 'center',
      textStyle: {
        fontSize: '18',
        fontWeight: '400',
        color: '#fff',

        textAlign: 'center',
      },
    },
    {
      // text: `正在烘干：${ value2 }吨`,
      left: '50%',
      top: '64%',
      textAlign: 'center',
      textStyle: {
        fontSize: '20',
        fontWeight: '400',
        color: '#fff',

        textAlign: 'center',
      },
    },
    // {
    //   // text: (value * 100).toFixed(0),
    //   left: '45%',
    //   top: '30%',
    //   textAlign: 'center',
    //   textStyle: {
    //     fontSize: 50,
    //     color: '#fff',
    //   },
    // },

    // {
    //   text: '%',
    //   left: '62%',
    //   top: '30%',
    //   textAlign: 'center',
    //   textStyle: {
    //     fontSize: 50,
    //     color: '#fff',
    //   },
    // },
  ],
  series: [
    {
      type: 'liquidFill',
      radius: '100%',
      z: 6,
      center: [ '50%', '50%' ],
      amplitude: 20,
      backgroundStyle: {
        borderWidth: 1,
        color: 'rgba(148, 89, 11, 0.5)', // 球体
      },
      color: [
        {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 1,
              color: '#ffba62',
            },
            {
              offset: 0,
              color: '#ffba62',
            },
          ],
          globalCoord: false,
        },
        {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 1,
              color: '#ffae5f',
            },
            {
              offset: 0,
              color: '#ffae5f',
            },
          ],
          globalCoord: false,
        },
        {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 1,
              color: '#ffa95f',
            },
            {
              offset: 0,
              color: '#ffa95f',
            },
          ],
          globalCoord: false,
        },
      ],
      data: [
        value + 0.02,
        {
          value: value - 0.01,
          direction: 'left',
        },
        value - 0.01,
      ],
      label: {
        normal: {
          formatter: '',
        },
      },
      outline: {
        show: true,
        itemStyle: {
          borderWidth: 0,
        },
        borderDistance: 0,
      },
    },
  ],
};

export default option;
