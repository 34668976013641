<template>
  <div class="dyr-service">
    <Title
      class="dyr-service-title"
      njTitle="top-title"
      typeOf="drying-page"
      :title="`${address}数字烘干`"
    ></Title>

    <div class="dyr-service-content">
      <div class="dyr-service-left">
        <Power :powerData="dryingpowerData"></Power>
        <Service class="dyr-service-service"></Service>
        <LastYear class="dyr-service-service" :dryingchartData="dryingchartData"></LastYear>
      </div>
      <div class="dyr-service-middle">
        <Mddle :dryingstoreData="dryingstoreData" />
      </div>
      <div class="dyr-service-right">
        <Right :dryingorderInfoData="dryingorderInfoData" />
        <Right_fin class="right_fin" :getorderInfoData="getorderInfoData"/>
      </div>
    </div>
    <bottomNav class="middle-bottomNav" />
  </div>
</template>

<script>
import Title from './components/title.vue';
import Power from './power.vue';
import Service from './service.vue';
import LastYear from './lastYear.vue';
import Mddle from './middle/index.vue';
import bottomNav from './components/bottomNav.vue';
import Right from './right/index.vue';
import Right_fin from './right/right_fin.vue';

import { dryingpower ,dryingchart,dryingstore,dryingorderInfo ,getorderInfo } from '@/api/njApi/honggan.js';

export default {
  components: {
    Title,
    Power,
    Service,
    LastYear,
    Mddle,
    bottomNav,
    Right,
    Right_fin,
  },
  created(){
    this.getdryingpower();
    this.address = localStorage.getItem('address')
  },
  data(){
    return{
      address:'',
      dryingpowerData:'',
      dryingchartData:'',
      dryingstoreData:'',
      dryingorderInfoData:'',
      getorderInfoData:''
    };
  },
  methods: {
   
    getdryingpower(){
      // 获取烘干能力信息
      dryingpower({
        name:this.address+'烘干'
      }).then(res => {
        console.log(res);
        this.dryingpowerData=res.data;
      }).catch((err => {
        console.log(err);
      }));
      // 获取烘干服务及对比上年情况
      dryingchart({
        name:this.address+'烘干'
      }).then(res => {
        this.dryingchartData=res.data;

      }).catch((err => {
        console.log(err);
      }));
      // 获取顶部栏和两个球状数据
      dryingstore({
        name:this.address+'烘干'
      }).then(res => {
        this.dryingstoreData=res.data;
      }).catch((err => {
        console.log(err);
      }));
      // 获取订单情况
      dryingorderInfo({

      }).then(res => {
        console.log(res);
        this.dryingorderInfoData=res.data;
      }).catch((err => {
        console.log(err);
      }));
      // 获取已完成订单
      getorderInfo({

      }).then(res => {
        console.log(res);
        this.getorderInfoData=res.data;
      }).catch((err => {
        console.log(err);
      }));

    }
  },
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}
@function companyH($num) {
  @return ($num * 100)/1080;
}
.dyr-service {
  position: relative;
  width: companyW(1920vw);
  height: companyH(1080vh);
  box-sizing: border-box;
  background-image: url(../../assets/drying-page/bcg-img.png);
  background-color: rgba(8, 27, 25);
  background-size: cover;
  background-repeat: no-repeat;

   .dyr-service-title {
  padding-top: companyH(8vh);
  width: companyW(1820vw);
  height: companyH(70vh);
  margin: 0 auto;
  background-size: contain;
  background-repeat: no-repeat;
 }
  .dyr-service-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .dyr-service-left {
      width: companyW(482vw);
      height: companyH(978vh);
      margin-left: companyW(20vw);
      .dyr-service-service {
        margin-top: companyH(21vh);
      }
    }
    .dyr-service-right {
      margin-right: companyW(20vw);
      height: companyH(978vh);
      .right_fin {
        margin-top: companyH(21vh);
      }
    }
  }
  .middle-bottomNav {
    position: absolute;
    bottom: 0;
    left: companyW(632vw);
    background-image: url(../../assets/drying-page/bottomNav.png);
    background-size: contain;
    background-repeat: no-repeat;
  }
}
</style>
